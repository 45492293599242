<template>
  <BaseCard
    title="datos de pago"
    collapsable
    :footer-visible="false"
    :resource="$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES"
    actions-visible
  >
    <template #actions>
      <b-link
        class="text-indigo mr-1 font-small-4"
        :disabled="paymentLinkVisible"
        @click.stop="creationModalVisible = true"
      >
        <u>+ Añadir pago</u>
      </b-link>
    </template>
    <b-row class="p-2">
      <b-col>
        <b-row align-v="start">
          <b-col
            sm="12"
            md="4"
            class="pb-1"
          >
            <div class="font-weight-bold">
              Cuenta bancaria:
            </div>
            <div class="text-light">
              {{ bankAccountName }}
            </div>
          </b-col>
          <b-col
            sm="12"
            md="4"
            class="pb-1"
          >
            <div class="font-weight-bold">
              Forma de pago:
            </div>
            <div class="text-light">
              {{ typePaymentName }}
            </div>
          </b-col>
          <b-col
            sm="12"
            md="4"
            class="pb-1"
          >
            <div class="font-weight-bold">
              Fecha de pago:
            </div>
            <div class="text-light">
              {{ invoiceDate }}
            </div>
          </b-col>
          <b-col
            cols="6"
            class="pb-1"
          >
            <b-form-checkbox
              :checked="sendPaymentDocument"
              value="1"
              disabled
              :unchecked-value="null"
            >
              Envio de documento de pago
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="6"
            class="pb-1"
          >
            <b-form-checkbox
              :checked="withholdingDocumentReceived"
              disabled
              value="1"
              :unchecked-value="null"
            >
              Documento de retención recibido
            </b-form-checkbox>
          </b-col>
          <PurchaseInvoicePaymentCreationModal
            v-model="creationModalVisible"
            :purchase-invoice="purchaseInvoice"
          />
        </b-row>
      </b-col>
      <b-col>
        <PurchaseInvoicePaymentList :purchase-invoice="purchaseInvoice" />
      </b-col>
    </b-row>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import PurchaseInvoicePaymentCreationModal from '@/components/purchase-invoices/modal/PurchaseInvoicePaymentCreationModal.vue'
import PurchaseInvoicePaymentList from '@/components/purchase-invoices/list/PurchaseInvoicePaymentList.vue'

export default {
  name: 'PurchaseInvoicesBillingCard',
  components: {
    PurchaseInvoicePaymentList, PurchaseInvoicePaymentCreationModal, BaseCard,
  },
  data() {
    return {
      creationModalVisible: false,
    }
  },
  computed: {
    ...mapGetters('purchaseInvoice', {
      purchaseInvoice: 'getPurchaseInvoice',
    }),
    withholdingDocumentReceived() {
      return this.purchaseInvoice?.withholding_document_received
    },
    bankAccountName() {
      return this.purchaseInvoice?.bank_account?.name || 'Sin cuenta bancaria'
    },
    typePaymentName() {
      return this.purchaseInvoice?.type_payment?.name || 'Sin forma de pago'
    },
    sendPaymentDocument() {
      return this.purchaseInvoice?.send_payment_document
    },
    invoiceDate() {
      return this.purchaseInvoice?.computation_date ? new Date(this.purchaseInvoice.computation_date).toLocaleDateString() : 'Sin fecha de pago'
    },
    paymentLinkVisible() {
      return this.purchaseInvoice?.status?.name === 'Pagada'
    },
  },
}
</script>

<style scoped>

</style>
